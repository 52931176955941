import { isValidMail } from '../../../../../../Common/Resources/src_front/js/helper-functions/isValidMail';

export function initCreateAccountEmail() {
    // VARS
    const form = document.querySelector('[data-create-account-form]');
    const checkbox = document.querySelector('[data-create-account-checkbox]');
    const inputEmail = document.querySelector('[data-create-account-email]');
    const selectorsErrorMessage = {
        email: '[data-error-email]',
        checkbox: '[data-error-checkbox]',
    };

    if (!form) return;

    // LISTENERS
    form.addEventListener('submit', handleOnSubmit, false);

    // HANDLERS
    function handleOnSubmit(event) {
        event.preventDefault();
        validateForm();
    }

    // METHODS & FUNCTIONS
    function validateForm() {
        let isValid = true;
        let selectorErrorMessageElems = [];

        if (!isValidMail(inputEmail.value.trim())) {
            isValid = false;
            selectorErrorMessageElems.push(selectorsErrorMessage.email);
        }

        if (!checkbox.checked) {
            isValid = false;
            selectorErrorMessageElems.push(selectorsErrorMessage.checkbox);
        }

        hideAllErrorMessage();

        isValid ? form.submit() : showErrorMessage(selectorErrorMessageElems);
    }

    function showErrorMessage(selectors) {
        selectors.forEach((selector) => {
            document.querySelector(selector).classList.add('is-visible');
        });
    }

    function hideAllErrorMessage() {
        Object.values(selectorsErrorMessage).forEach((selector) => {
            document.querySelector(selector).classList.remove('is-visible');
        });
    }
}
